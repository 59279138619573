<template>
  <div>
    <div
      v-if="queryParams1.params.organizationId"
      class="font-weight-medium title primary--text mt-2"
      style="font-size: 14px !important"
    >
      <m-icon
        icon="medexpert-organization"
        class="mx-2"
        color="primary"
      ></m-icon>

      {{ queryParams1.params.organizationId.Name }}
    </div>
    <div class="d-flex mt-4">
      <KibmdOverallRating
        :value="dataSource.Value"
        :description="dataSource.Description"
        :labels="labels"
      />

      <tfoms-finance
        :data="dataSource.MonthValues.map((e) => [e])"
        title="Общая оценка состояния качества и безопасности медицинской деятельности"
        hideIcon
        :factColor="'var(--v-primary-base)'"
        labelFact="Нежелательные события"
        :hideDatalabel="false"
        style="width: 100%"
        class="ml-5"
        :labels="labels"
        :subTitle="null"
        :period="queryParams1.params.periodType || 0"
        @itemClick="itemClick"
        :refs="dataSource.RefValues"
        :isClickLabel="false"
      >
      </tfoms-finance>
    </div>

    <tfoms-panel
      title="Оценка по показателям качества"
      small
      hide-icon
      :isClickLabel="false"
      :labels="labels"
      class="mt-4"
    >
      <kibmd-table2
        v-model="selectIndicator"
        :data="dataSource.IndicatorValues"
        :refs="dataSource.RefValues"
      ></kibmd-table2>
    </tfoms-panel>

    <v-row class="mt-4" v-if="selectIndicator">
      <v-col
        ><KibmdTableCheck
          v-model="selectCheck"
          :data="externalIndicator.Audits"
          :labels="labels1"
          :refs="dataSource.RefValues"
        ></KibmdTableCheck>
      </v-col>
      <v-col
        ><KibmdTableCheckList
          :data="externalIndicator.CheckLists"
          @input="
            $router.push({
              name: $event.isCheckOperation
                ? 'CheckOperationRun'
                : 'CheckListEdit',
              params: { objectId: $event.Id },
            })
          "
          :labels="labels2"
          :refs="dataSource.RefValues"
        ></KibmdTableCheckList>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import KibmdService from "@/services/KibmdService";
import tfomsFinance from "@/views/analysis/tfoms/components/tfomsFinance";
import KibmdOverallRating from "../../components/kibmdOverallRating.vue";
import kibmdTab from "../../mixins/kibmdTab";

export default {
  name: "kibmd-more-tab1",
  components: {
    KibmdOverallRating,
    tfomsFinance,
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
    kibmdTable2: () => import("../../components/kibmdTable2.vue"),
    KibmdTableCheck: () => import("../../components/kibmdTableCheck.vue"),
    KibmdTableCheckList: () =>
      import("../../components/kibmdTableCheckList.vue"),
  },
  mixins: [kibmdTab],
  data: function () {
    return {
      dataSource: {},
      externalIndicator: {},
      selectIndicator: null,
      selectCheck: null,
      periodNum: null,
    };
  },

  computed: {
    labels() {
      return [
        this.queryParams?.params?.Year + " год",
        this.periodNum !== null
          ? moment().set("month", this.periodNum).format("MMMM")
          : null,
        this.queryParams1.params?.indicatorId?.Name,
        this.queryParams1.params?.organizationId?.Name,
      ].filter(Boolean);
    },
    labels1() {
      return [
        this.queryParams?.params?.Year + " год",
        this.periodNum !== null
          ? moment().set("month", this.periodNum).format("MMMM")
          : null,
        this.selectIndicator?.Indicator?.Name,
      ].filter(Boolean);
    },
    labels2() {
      return [
        ...this.labels1,
        this.selectCheck?.AuditOperation?.Num
          ? "Проверка: №" + this.selectCheck?.AuditOperation?.Num
          : null,
      ].filter(Boolean);
    },
  },
  watch: {
    periodNum(val) {
      this.updateQueryParams1({ periodNum: val === null ? null : val + 1 });
    },
    async selectIndicator(val) {
      if (val) {
        this.externalIndicator = (
          await KibmdService.kibmd1.tab1.getExternalIndicatorAnalysisByOperation(
            {
              ...this.queryParams.params,
              ...this.queryParams1.params,
              indicatorId2: val,
              ...this.filterParams.params.selected0,
            }
          )
        ).data;
      }
      this.selectCheck = null;
    },

    async selectCheck(val, old) {
      if (val === null && val === old) return;
      this.externalIndicator.CheckLists = (
        await KibmdService.kibmd1.tab1.GetExternalAuditCheckOperations({
          ...this.queryParams.params,
          ...this.queryParams1.params,
          indicatorId2: this.selectIndicator,
          auditOperationId: val?.AuditOperation?.Id,
        })
      ).data.CheckLists;
    },
  },

  mounted() {
    this.init();

    this.$watch(
      () => this.queryParams1.params,
      () => {
        this.init();
      }
    );
  },
  methods: {
    itemClick(e) {
      if (e === this.periodNum) {
        this.periodNum = null;
      } else {
        this.periodNum = e;
      }
    },
    async init() {
      this.loading = true;
      this.selectIndicator = null;

      const { data } = await KibmdService.kibmd1.tab1.get({
        ...this.queryParams.params,
        ...this.queryParams1.params,
      });
      this.dataSource = data;
      this.loading = false;
    },
  },
};
</script>
